/* eslint-disable import/no-extraneous-dependencies */

import Swiper from 'swiper';
import { Navigation, A11y, Keyboard } from 'swiper/modules';
import { debounce } from 'lodash'; // eslint-disable-line import/no-extraneous-dependencies
import { scrollLock } from '../util';

const Header = (() => {
    let uspSwiper;
    const isBound = {
        large: false,
        small: false,
    };

    let isMouseNavigation = false; // eslint-disable-line no-unused-vars

    /**
     * Initializes USP swiper
     */
    const initUSPSwiper = () => {
        const isCampaignPage = $('.main-campaign-header').length > 0;
        const wrapperClass = isCampaignPage ? '.main-campaign-header' : '.header-max-col';
        const swiperClass = '.js-header-usp-swiper';
        const minWidthNotMobile = 768;

        const options = {
            modules: [Navigation, A11y, Keyboard],
            spaceBetween: 3,
            slidesPerView: 1,
            slidesPerGroup: 1,
            watchOverflow: true,
            watchSlidesProgress: true,
            touchRatio: 1,
            keyboard: {
                enabled: true,
            },
            navigation: {
                nextEl: `${wrapperClass} .arrow-next`,
                prevEl: `${wrapperClass} .arrow-prev`,
            },
            a11y: {
                prevSlideMessage: `${window.resources['slider.prev.slide.label']}`,
                nextSlideMessage: `${window.resources['slider.next.slide.label']}`,
                firstSlideMessage: `${window.resources['slider.first.slide.label']}`,
                lastSlideMessage: `${window.resources['slider.last.slide.label']}`,
            },
            breakpoints: {
                [minWidthNotMobile]: {
                    spaceBetween: 40,
                    slidesPerView: 'auto',
                    slidesPerGroupAuto: 'true',
                },
            },
        };

        if (typeof uspSwiper === 'undefined') {
            uspSwiper = new Swiper(swiperClass, options);
        }
    };

    /**
     * Calculates some dynamic universal sizes and sets adds it to variables that can be used in css
     * Also ads mouse navigation class to body, (removed when keyboard nav)
     */
    const globalCssVariables = () => {
        // Calculate scrollbar width
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        // Calculate header height
        const header = document.querySelector('.main-page-header');
        const headerHeight = header ? header.offsetHeight : 0;

        // Set a CSS variables
        document.documentElement.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
        document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);

        function handleMouseDown() {
            isMouseNavigation = true;
            document.body.classList.add('mouse-navigation');
        }

        function handleKeyDown() {
            isMouseNavigation = false;
            document.body.classList.remove('mouse-navigation');
        }

        document.addEventListener('mousedown', handleMouseDown);
        document.addEventListener('keydown', handleKeyDown);
    };

    /**
     * Touch Menu: Update state (show/hide) and manage scroll lock and overlay.
     * @param {boolean} isVisible - Whether the menu should be visible.
     */
    const updateTouchMenuState = (isVisible) => {
        const menuWrapper = $('.main-header-menu-wrapper');
        const subMenuWrapper = $('.main-header-sub-menu-wrapper');
        const mainLinks = $('.main-header-menu-link');
        if (isVisible) {
            menuWrapper.addClass('takeplace');
            setTimeout(() => {
                menuWrapper.addClass('visible');
            }, 50);
            scrollLock('on');
            $('body').addClass('has-menu-popup-overlay');
        } else {
            menuWrapper.removeClass('visible');
            subMenuWrapper.removeClass('visible');
            mainLinks.attr('aria-expanded', 'false');
            setTimeout(() => {
                menuWrapper.removeClass('takeplace');
            }, 300);
            scrollLock('off');
            $('body').removeClass('has-menu-popup-overlay');
        }
    };

    /**
     * Touch Menu: Click events
     */
    const touchMenuToggle = () => {
        $('.js-hamburger-toggle').on('click', () => {
            const menuWrapper = $('.main-header-menu-wrapper');
            const isVisible = !menuWrapper.hasClass('visible');
            updateTouchMenuState(isVisible);
        });

        $('.main-header-menu-link').on('click', (event) => {
            const link = $(event.currentTarget);
            const subMenuWrapper = link.next('.main-header-sub-menu-wrapper');

            if (subMenuWrapper.length) {
                event.preventDefault(); // Prevent link navigation
                subMenuWrapper.toggleClass('visible');

                const isExpanded = subMenuWrapper.hasClass('visible');
                link.attr('aria-expanded', isExpanded ? 'true' : 'false');
            }
        });

        $('.js-touch-menu-back').on('click', () => {
            const subMenuWrapper = $('.main-header-sub-menu-wrapper');
            subMenuWrapper.removeClass('visible');
            $('.main-header-menu-link').attr('aria-expanded', 'false');
        });
    };

    /**
     * Touch Menu: Click events
     */
    const jobsMenuToggle = () => {
        $('.recruitment-header .js-jobs-menu-toggle, .recruitment-header .js-header-menu-item').on('click', () => {
            $('.recruitment-header .site-menu').toggleClass('visible');
            $('body').toggleClass('stop-scrolling-on-touch');
        });
    };

    /**
     * Touch Menu: cleanup after resize
     */
    const touchMenuClean = () => {
        if (window.innerWidth >= 1280) {
            const menuWrapper = $('.main-header-menu-wrapper');
            if (menuWrapper.hasClass('visible')) {
                updateTouchMenuState(false); // Ensure the menu is hidden
            }
            const menusUBWrapper = $('.main-header-sub-menu-wrapper');
            menusUBWrapper.attr('aria-expanded', 'false');
        }
    };

    /**
     * Desktop Main menu: (re)position menu sub flags
     */
    const positionDesktopMenuFlags = () => {
        if (window.innerWidth >= 1280) {
            $('.main-header-sub-menu').each((index, element) => {
                const itemCount = $(element).find('.main-header-sub-menu-column').length;

                // Only process menus with 1 or 2 columns (3 and 4 are full width)
                if (itemCount === 1 || itemCount === 2) {
                    const parentLink = $(element).closest('.main-header-menu-item');

                    if (parentLink.length) {
                        const parentWidth = parentLink.outerWidth();
                        const leftOffsetParent = parentLink.offset().left;
                        const parentLinkCenter = leftOffsetParent + (parentWidth / 2);

                        const containerLeftOffset = (window.innerWidth - 1120) / 2;
                        const centerInsideContainer = parentLinkCenter - containerLeftOffset;

                        const subMenuWidth = itemCount === 1 ? 400 : 752; // 1 item = 400px, 2 items = 752px
                        const submenuHalfWidth = subMenuWidth / 2;

                        // Temporarily show the wrapper for position calculations
                        const subMenuWrapper = $(element).closest('.main-header-sub-menu-wrapper');
                        subMenuWrapper.css('display', 'block');

                        // Calculate the ideal left position for the submenu
                        let leftPosition = centerInsideContainer - submenuHalfWidth;

                        // Ensure the submenu stays within the 1120 container
                        if (leftPosition < 0) leftPosition = 0;
                        else if (leftPosition + subMenuWidth > 1120) leftPosition = 1120 - subMenuWidth;

                        // Apply the calculated left position to the submenu
                        $(element).css('left', `${leftPosition}px`); // Use template literal here

                        // Revert the inline `display` property
                        subMenuWrapper.css('display', '');
                    }
                }
            });
        } else {
            // clean up positioning for touch
            $('.main-header-sub-menu').css('left', '');
        }
    };

    /**
     * Keyboard / Mouse Navigation Main menu (All devices / screensizes):
     */
    // Keyboard / Mouse Navigation: Bind events for large screens
    const bindLargeScreenEvents = () => {
        const collapseAllSubmenus = () => {
            $('.main-header-menu-item > a[aria-haspopup="true"]').each((_, menuLink) => {
                const $menuLink = $(menuLink);
                const $submenu = $menuLink.next('.main-header-sub-menu-wrapper');
                $submenu.removeClass('visible');
                $menuLink.attr('aria-expanded', 'false');
            });
        };

        const handleBlur = () => {
            setTimeout(() => {
                const isFocusOutsideMenu = !$('.main-header-menu-wrapper').find(':focus').length;
                if (isFocusOutsideMenu) {
                    collapseAllSubmenus();
                }
            }, 50);
        };

        const showMenu = ($menuLink, $submenu) => {
            collapseAllSubmenus();
            if ($submenu.length) {
                $menuLink.attr('aria-expanded', 'true');
                $submenu.addClass('visible');
            }
        };

        const hideMenu = ($submenu) => {
            if ($submenu.length) {
                setTimeout(() => {
                    if (!$submenu.find(':hover, :focus').length) {
                        $submenu.removeClass('visible');
                        $submenu.prev('a[aria-haspopup="true"]').attr('aria-expanded', 'false');
                    }
                }, 50);
            }
        };

        // Add event handlers for all menu links
        $('.main-header-menu-item > a').each((_, menuLink) => {
            const $menuLink = $(menuLink);
            const $submenu = $menuLink.next('.main-header-sub-menu-wrapper');

            $menuLink.on('focus', () => showMenu($menuLink, $submenu));
            $menuLink.on('mouseenter', () => showMenu($menuLink, $submenu));
            $menuLink.on('mouseleave', () => hideMenu($submenu));
            $submenu.on('mouseleave', () => hideMenu($submenu));
        });

        // Global focusout handler to collapse menus when focus leaves the entire menu
        $('.main-header-menu-wrapper').on('focusout', handleBlur);
    };

    // Keyboard / Mouse Navigation: Unbind events for large screens
    const unbindLargeScreenEvents = () => {
        $('.main-header-menu-item > a[aria-haspopup="true"]').off('focus blur mouseenter mouseleave');
        $('.main-header-sub-menu-wrapper').off('mouseleave');
        $('.main-header-menu-wrapper').off('focusout');
    };

    // Keyboard Navigation: Bind events for small screens
    const bindSmallScreenEvents = () => {
        // Open submenu on focus
        const handleFocus = (e) => {
            const $target = $(e.currentTarget);
            const $parentMenuLink = $target.closest('.main-header-menu-item').children('a.main-header-menu-link');
            const $submenuWrapper = $parentMenuLink.next('.main-header-sub-menu-wrapper');

            $parentMenuLink.attr('aria-expanded', 'true'); // Update aria-expanded
            $submenuWrapper.addClass('visible');
        };

        // Close submenu on blur
        const handleBlur = (e) => {
            const $target = $(e.currentTarget);
            const $parentMenuLink = $target.closest('.main-header-menu-item').children('a.main-header-menu-link');
            const $submenuWrapper = $parentMenuLink.next('.main-header-sub-menu-wrapper');

            setTimeout(() => {
                if (!$submenuWrapper.find(':focus').length) {
                    $parentMenuLink.attr('aria-expanded', 'false'); // Update aria-expanded
                    $submenuWrapper.removeClass('visible');
                }
            }, 50);
        };

        // Track Tab key and close the menu when focus moves out
        const handleKeyUp = (e) => {
            const menuIsOpen = $('.main-header-menu-wrapper').hasClass('visible');
            if (e.key === 'Tab' && !$(document.activeElement).closest('.header-navigation').length && menuIsOpen) {
                updateTouchMenuState(false); // Assumes this function updates the menu state
            }
        };

        // Prevent default action for Enter key on menu items
        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
            }
        };

        $('.main-header-sub-menu-list-item a, .js-touch-menu-back').on('focus', handleFocus);
        $('.main-header-sub-menu-list-item a, .js-touch-menu-back').on('blur', handleBlur);
        $('.header-row-navigation .header-max-col').on('keyup', handleKeyUp);
        $('.js-touch-menu-back, .main-header-menu-link').on('keydown', handleKeyDown);
    };

    // Keyboard Navigation: Unbind events for small screens
    const unbindSmallScreenEvents = () => {
        $('.main-header-sub-menu-list-item a, .js-touch-menu-back').off('focus').off('blur').off('keydown');
        $('.header-row-navigation .header-max-col').off('keyup');
        $('.js-touch-menu-back, .main-header-menu-link').off('keydown');
    };

    /**
     * Keyboard / Mouse Navigation: Main menu: toggle bindings from functions above for different screensizes
     */
    const keyboardMenuNavigation = () => {
        const windowWidth = window.innerWidth;

        if (windowWidth >= 1280) {
            if (!isBound.large) {
                bindLargeScreenEvents();
                isBound.large = true;
            }
            if (isBound.small) {
                unbindSmallScreenEvents();
                isBound.small = false;
            }
        } else {
            // If we're switching to a small screen
            if (!isBound.small) {
                bindSmallScreenEvents();
                isBound.small = true;
            }
            if (isBound.large) {
                unbindLargeScreenEvents();
                isBound.large = false;
            }
        }

        // skip the seamy input for keyboard navigation:
        const searchInput = $('.header-row-navigation .seamly-search-placeholder input.cx-search-box__input');

        if (searchInput.length && searchInput.attr('tabindex') !== '-1') {
            searchInput.attr('tabindex', '-1');
        }
    };

    /**
     * Desktop Service Menu: Set aria properties for A11y
     */
    const serviceMenuDt = () => {
        $('.country-selector').on('mouseenter', () => {
            $('.country-selector').attr('aria-expanded', 'true');
        });

        $('.country-selector').on('mouseleave', () => {
            $('.country-selector').attr('aria-expanded', 'false');
        });

        $('.service-menu').on('focusin focusout keyup', () => {
            if ($('.country-selector').is(':focus') || $('.dropdown-item:focus').length > 0) {
                $('.country-selector').attr('aria-expanded', 'true');
            } else {
                $('.country-selector').attr('aria-expanded', 'false');
            }
        });
    };

    /**
     * Keyboard Navigation: "Tab racing" holdingdown the Tab key; shooting through a page without triggering keyup
     * this way you easilly overshoot and not close a menu or dropdown by Tab keyup
     * to prevent having to bind all sorts of Tab keyup and keydown far outside of their scope
     */
    const preventTabRacing = () => {
        let tabKeyPressedCount = 0;
        let tabPressTimeout;
        let tabKeydownPaused = false;

        const handleTabKeydown = (e) => {
            // Only for Tab key
            if (e.key === 'Tab') {
                // If tabbing was paused, prevent default behavior
                if (tabKeydownPaused) {
                    e.preventDefault(); // Stop the native tabbing behavior
                }

                tabKeyPressedCount++;

                // If Tab key is pressed more than 2 times within 500ms, pause native tabbing behavior
                if (tabKeyPressedCount > 2) {
                    tabKeydownPaused = true;
                    clearTimeout(tabPressTimeout);
                    tabPressTimeout = setTimeout(() => {
                        tabKeydownPaused = false;
                        tabKeyPressedCount = 0; // Reset the counter
                    }, 500);
                }
            }
        };

        // This function ensures that the native tabbing resumes when the Tab key is released
        const handleTabKeyup = (e) => {
            if (e.key === 'Tab') {
                tabKeydownPaused = false;
                tabKeyPressedCount = 0;
            }
        };

        // Add the event listeners for keydown and keyup
        $(document).on('keydown', handleTabKeydown);
        $(document).on('keyup', handleTabKeyup);
    };

    /**
     * Handles counter in the top site header
     */
    const countdown = () => {
        const $counter = $('#promo-banner-counter');

        if ($counter.length > 0) {
            const $bannerContainer = $('.js-header-promo-banner-container');
            const $hoursValue = $('.js-promo-banner-counter-hours');
            const $minutesValue = $('.js-promo-banner-counter-minutes');
            const $secondsValue = $('.js-promo-banner-counter-seconds');
            const date = $counter.attr('data-date');
            const url = $counter.attr('data-header-banner-url');
            const countDownDate = new Date(date).getTime();

            const interval = setInterval(() => {
                const now = new Date().getTime();
                const distance = countDownDate - now;

                const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((distance % (1000 * 60)) / 1000);

                $hoursValue.text(hours);
                $minutesValue.text(minutes);
                $secondsValue.text(seconds);

                if (distance < 0) {
                    clearInterval(interval);
                    $bannerContainer.addClass('d-none');

                    $.ajax({
                        url,
                        type: 'GET',
                        dataType: 'json',
                        success: data => {
                            const $serviceMenu = $('.js-service-menu');
                            $serviceMenu.before(data.headerBannerHtml);

                            const $uspSwiper = $('.js-header-usp-swiper');

                            if ($uspSwiper.length > 0) {
                                uspSwiper = undefined;
                                initUSPSwiper();
                            }
                        },
                    });
                }
            }, 1000);
        }
    };

    /**
     * Initializes event listeners for the top page header
     */
    const initEventListeners = () => {
        $('.js-become-member').on('click', () => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
                event: 'precheckout',
                step: '1',
                component_name: 'join',
            });
        });
    };

    return {
        init() {
            globalCssVariables();
            touchMenuToggle();
            positionDesktopMenuFlags();
            initUSPSwiper();
            countdown();
            keyboardMenuNavigation();
            serviceMenuDt();
            preventTabRacing();
            initEventListeners();
            jobsMenuToggle();
        },
        resize() {
            globalCssVariables();
            touchMenuClean();
            positionDesktopMenuFlags();
            keyboardMenuNavigation();
        },
    };
})();

export default (() => {
    $(document).ready(Header.init);
    $(window).on('resize', debounce(Header.resize, 300));
})();
