import { formatName } from './../util';

const GtmParametersBuilder = require('./gtmParametersBuilder');
const GtmCheckoutBuilder = require('./gtmCheckoutBuilder');
const GtmEcommBuilder = require('./gtmEcommBuilder');

function buildCheckoutOption(checkoutData) {
    const ecommData = new GtmEcommBuilder()
        .withCheckoutOption(checkoutData)
        .build();

    return new GtmParametersBuilder()
        .withEvent('ecomCheckoutOption')
        .withEcommerce(ecommData)
        .build();
}

function buildCheckout(checkoutData) {
    const ecommData = new GtmEcommBuilder()
        .withCheckout(checkoutData)
        .build();

    return new GtmParametersBuilder()
        .withEvent('ecomCheckout')
        .withEcommerce(ecommData)
        .build();
}

function buildPurchaseOption(checkoutData, user) {
    const ecommData = new GtmEcommBuilder()
        .withPurchaseOption(checkoutData)
        .build();

    return new GtmParametersBuilder()
        .withEvent('ecomPurchase')
        .withEcommerce(ecommData)
        .withUser(user)
        .build();
}

/**
 * @description Function for pushing GA4 event after clicking a CTA on second step of new checkout
 */
function pushMembershipStepCTAEvent() {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: 'next_step',
        type: 'membership checkout',
        step_name: 'select membership',
        step_index: 2,
    });
}

/**
 * Handles view_details event
 * @param {string} membershipType - Membership type
 */
const pushMembershipViewDetailsEvent = membershipType => {
    if (!membershipType) {
        return;
    }

    const event = new GtmParametersBuilder()
        .withEvent('view_details')
        .withParameter('type', 'memberships')
        .withParameter('membership', membershipType.toLowerCase())
        .build();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
};

function pushMembershipHideDetailsEvent(membershipType) {
    if (!membershipType) {
        return;
    }

    const event = new GtmParametersBuilder()
        .withEvent('hide_details')
        .withParameter('type', 'memberships')
        .withParameter('membership', membershipType.toLowerCase())
        .build();

    window.dataLayer.push(event);
}

function pushPaymentMethodLoaded(products, option) {
    if (!products) {
        return;
    }

    const checkoutData = new GtmCheckoutBuilder()
        .withActionField({ step: 4, option })
        .withProducts(products.map(product => product))
        .build();
    const gtmParams = buildCheckout(checkoutData);

    window.dataLayer.push(gtmParams);
}

function pushConfirmationPageLoaded(actionField, products, marketingID, user, userID) {
    if (!products) {
        return;
    }

    const checkoutData = new GtmCheckoutBuilder()
        .withActionField(actionField)
        .withProducts(products.map(product => formatName(product)))
        .withMarketingID(marketingID)
        .build();
    const gtmParams = buildPurchaseOption(checkoutData, user);
    // Idetify user for Tracedock
    window.dataLayer.push({ userId: userID });
    window.dataLayer.push(gtmParams);
    localStorage.removeItem('selectedClub');
    localStorage.removeItem('selectedMembershipType');
    localStorage.removeItem('selectedMembershipDuration');
    localStorage.removeItem('selectedAddOns');
}

/**
 * Handles purchase event
 * @param {?Object} data - Event data
 * @param {Object} userData - User data
 * @param {Object} actionData - Action data
 */
const pushPurchaseEvent = (data, userData, actionData) => {
    if (!(data && data.items)) {
        return;
    }

    const membership = data.items.find(item => item.item_category === 'subscription');

    if (!membership) {
        return;
    }

    const user = {
        user_email: userData.user_email,
        user_phone_number: userData.user_phone_number,
        user_first_name: userData.user_first_name,
        user_last_name: userData.user_last_name,
        user_gender: userData.user_gender,
        user_city: userData.user_city,
        user_country: userData.user_country_code,
        user_street: userData.user_street,
        user_postal_code: userData.user_zip_code,
        user_age_group: userData.user_age_group,
        member_id: actionData.peopleId,
    };

    const club = {
        club_name: data.club_name,
        club_id: data.club_id,
        club_services: data.club_services,
        club_founding_member: data.club_founding_member,
    };

    const ecommerce = {
        transaction_id: actionData.id,
        affiliation: actionData.affiliation,
        order_amount: actionData.orderAmount,
        value: actionData.value,
        currency: data.currency,
        payment_type: actionData.paymentType,
        payment_provider: actionData.paymentProvider,
        items: data.items,
    };

    const event = new GtmParametersBuilder()
        .withEvent('purchase')
        .withParameter('membership', membership.item_name)
        .withParameter('membership_duration', membership.item_variant)
        .withParameter('payment_interval', data.payment_interval)
        .withParameter('user', user)
        .withParameter('club', club)
        .withParameter('ecommerce', ecommerce)
        .build();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(event);
};

function pushOriginalLocation(originalLocation) {
    const event = new GtmParametersBuilder()
        .withParameter('original_location', originalLocation)
        .build();

    window.dataLayer.push(event);
}

function pushPageView(data) {
    if (!data) {
        return;
    }

    const events = [
        new GtmParametersBuilder()
            .withParameter('page', null)
            .build(),
        new GtmParametersBuilder()
            .withEvent('page_view')
            .withPage(data)
            .withTraffic(data)
            .build(),
    ];

    events.forEach((event) => {
        window.dataLayer.push(event);
    });
}

/**
 * Handles begin_checkout event on entering step 4 of the checkout
 * @param {?Object} data - Event data
 */
const pushPersonalInformationEvent = data => {
    if (!(data && data.items)) {
        return;
    }

    const membership = data.items.find(item => item.item_category === 'subscription');

    if (!membership) {
        return;
    }

    const club = {
        club_name: data.club_name,
        club_id: data.club_id,
        club_services: data.club_services,
        club_founding_member: data.club_founding_member,
    };

    const ecommerce = {
        currency: data.currency,
        items: data.items,
    };

    const event = new GtmParametersBuilder()
        .withEvent('begin_checkout')
        .withParameter('membership', membership.item_name)
        .withParameter('membership_duration', membership.item_variant)
        .withParameter('payment_interval', data.payment_interval)
        .withParameter('club', club)
        .withParameter('ecommerce', ecommerce)
        .build();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(event);
};

/**
 * Handles add_to_cart event
 * @param {?Object} data - Event data
 * @param {boolean} isOnlyAddonsAdded - True if only addons are added to cart, False otherwise
 * @param {boolean} isOnlyMembershipAdded - True if only membership and club are added to cart, False otherwise
 */
const pushAddToCartEvent = (data, isOnlyAddonsAdded, isOnlyMembershipAdded) => {
    if (!(data && data.items)) {
        return;
    }

    const membership = data.items.find((item) => item.item_category === 'subscription');

    if (!membership) {
        return;
    }

    let items = [];

    if (isOnlyAddonsAdded) {
        items = data.items.filter((item) => item.item_category === 'add-on');
    } else if (isOnlyMembershipAdded) {
        items = data.items.filter((item) => item.item_category !== 'add-on');
    } else {
        items = data.items;
    }

    if (items.length <= 0) {
        return;
    }

    const club = {
        club_name: data.club_name,
        club_id: data.club_id,
        club_services: data.club_services,
        club_founding_member: data.club_founding_member,
    };

    const ecommerce = {
        currency: data.currency,
        items,
    };

    const event = new GtmParametersBuilder()
        .withEvent('add_to_cart')
        .withParameter('membership', membership.item_name)
        .withParameter('membership_duration', membership.item_variant)
        .withParameter('payment_interval', data.payment_interval)
        .withParameter('club', club)
        .withParameter('ecommerce', ecommerce)
        .build();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(event);
};

/**
 * Handles remove_from_cart event
 * @param {?Object} data - Event data
 * @param {boolean} isOnlyAddonsRemoved - True if only addons are removed from cart, False otherwise
 * @param {boolean} isOnlyMembershipRemoved - True if only membership and club are removed from cart, False otherwise
 */
const pushRemoveFromCartEvent = (data, isOnlyAddonsRemoved, isOnlyMembershipRemoved) => {
    if (!(data && data.items)) {
        return;
    }

    const membership = data.items.find((item) => item.item_category === 'subscription');

    if (!membership) {
        return;
    }

    let items = [];

    if (isOnlyAddonsRemoved) {
        items = data.items.filter((item) => item.item_category === 'add-on');
    } else if (isOnlyMembershipRemoved) {
        items = data.items.filter((item) => item.item_category !== 'add-on');
    } else {
        items = data.items;
    }

    if (items.length <= 0) {
        return;
    }

    const club = {
        club_name: data.club_name,
        club_id: data.club_id,
        club_services: data.club_services,
        club_founding_member: data.club_founding_member,
    };

    const ecommerce = {
        currency: data.currency,
        items,
    };

    const event = new GtmParametersBuilder()
        .withEvent('remove_from_cart')
        .withParameter('membership', membership.item_name)
        .withParameter('membership_duration', membership.item_variant)
        .withParameter('payment_interval', data.payment_interval)
        .withParameter('club', club)
        .withParameter('ecommerce', ecommerce)
        .build();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(event);
};

/**
 * Handles add_shipping_info event on entering step 5 of the checkout
 * @param {?Object} data - Event data
 */
const pushPaymentMethodEvent = data => {
    if (!(data && data.items)) {
        return;
    }

    const membership = data.items.find(item => item.item_category === 'subscription');

    if (!membership) {
        return;
    }

    const club = {
        club_name: data.club_name,
        club_id: data.club_id,
        club_services: data.club_services,
        club_founding_member: data.club_founding_member,
    };

    const ecommerce = {
        currency: data.currency,
        items: data.items,
    };

    const event = new GtmParametersBuilder()
        .withEvent('add_shipping_info')
        .withParameter('membership', membership.item_name)
        .withParameter('membership_duration', membership.item_variant)
        .withParameter('payment_interval', data.payment_interval)
        .withParameter('club', club)
        .withParameter('ecommerce', ecommerce)
        .build();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(event);
};

/**
 * Handles select_club event
 * @param {?Object} data - Event data
 * @param {string} type - List/club details (popup) type
 * @param {string} view - Map/list view
 * @param {number} position - Club's position in the list, applicable for the list view only
 */
const pushSelectClubEvent = (data, type, view, position) => {
    if (!data) {
        return;
    }

    const club = {
        club_name: data.club_name,
        club_id: data.club_id,
        club_services: data.club_services,
        club_founding_member: data.club_founding_member,
    };

    if (position && view === 'list view') {
        club.club_list_position = position.toString();
    }

    const event = new GtmParametersBuilder()
        .withEvent('select_club')
        .withParameter('type', type)
        .withParameter('view_type', view)
        .withParameter('club', club)
        .build();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
};

/**
 * Handles error event
 * @param {string} errorType - Error type
 * @param {string} message - Error message
 */
const pushErrorEvent = (errorType, message) => {
    const error = {
        error_type: errorType,
        error_message: message,
    };

    const events = [
        new GtmParametersBuilder()
            .withParameter('error', null)
            .build(),
        new GtmParametersBuilder()
            .withEvent('error')
            .withError(error)
            .build(),
    ];

    window.dataLayer = window.dataLayer || [];

    events.forEach(event => {
        window.dataLayer.push(event);
    });
};

/**
 * Handles select_filter / deselect_filter event
 * @param {string} type - Filter type
 * @param {boolean} isSelected - True if filter is selected, False otherwise
 */
const pushSelectFilterEvent = (type, isSelected) => {
    const eventName = isSelected ? 'deselect_filter' : 'select_filter';
    const event = new GtmParametersBuilder()
        .withEvent(eventName)
        .withParameter('type', type)
        .build();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
};

/**
 * @description Function for handling view selection event
 * @param {string} viewTypeName - view type name
 */
function pushViewTypeSelectEvent(viewTypeName) {
    window.dataLayer = window.dataLayer || [];
    const event = new GtmParametersBuilder()
        .withEvent('change_view')
        .withParameter('type', viewTypeName)
        .build();

    window.dataLayer.push(event);
}

/**
 * Handles payment_method_selected event
 * @param {string} option - Option
 */
const pushPaymentMethodSelectedEvent = option => {
    if (!option) {
        return;
    }

    const checkoutData = new GtmCheckoutBuilder()
        .withActionField({ step: 4, option })
        .build();
    const gtmParams = buildCheckoutOption(checkoutData);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(gtmParams);
};

/**
 * Handles select_membership event
 * @param {?Object} data - Event data
 */
const pushSelectMembershipEvent = data => {
    if (!(data && data.items)) {
        return;
    }

    const membership = data.items.find(item => item.item_category === 'subscription');

    if (!membership) {
        return;
    }

    const club = {
        club_name: data.club_name,
        club_id: data.club_id,
        club_services: data.club_services,
        club_founding_member: data.club_founding_member,
    };

    const event = new GtmParametersBuilder()
        .withEvent('select_membership')
        .withParameter('membership', membership.item_name)
        .withParameter('payment_interval', data.payment_interval)
        .withParameter('club', club)
        .build();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
};

/**
 * Handles select_payment_interval event
 * @param {?Object} data - Event data
 */
const pushSelectPaymentIntervalEvent = data => {
    if (!(data && data.items)) {
        return;
    }

    const membership = data.items.find(item => item.item_category === 'subscription');

    if (!membership) {
        return;
    }

    const club = {
        club_name: data.club_name,
        club_id: data.club_id,
        club_services: data.club_services,
        club_founding_member: data.club_founding_member,
    };

    const event = new GtmParametersBuilder()
        .withEvent('select_payment_interval')
        .withParameter('membership', membership.item_name)
        .withParameter('membership_duration', membership.item_variant)
        .withParameter('payment_interval', data.payment_interval)
        .withParameter('club', club)
        .build();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
};

/**
 * Handles add_payment_info event
 * @param {?Object} data - Event data
 * @param {string} paymentType - Payment type
 * @param {string} paymentProvider - Payment provider
 */
const pushPaymentInfoEvent = (data, paymentType, paymentProvider) => {
    if (!(data && data.items)) {
        return;
    }

    const membership = data.items.find(item => item.item_category === 'subscription');

    if (!membership) {
        return;
    }

    const club = {
        club_name: data.club_name,
        club_id: data.club_id,
        club_services: data.club_services,
        club_founding_member: data.club_founding_member,
    };

    const ecommerce = {
        currency: data.currency,
        payment_type: paymentType,
        payment_provider: paymentProvider,
        items: data.items,
    };

    const event = new GtmParametersBuilder()
        .withEvent('add_payment_info')
        .withParameter('membership', membership.item_name)
        .withParameter('membership_duration', membership.item_variant)
        .withParameter('payment_interval', data.payment_interval)
        .withParameter('club', club)
        .withParameter('ecommerce', ecommerce)
        .build();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(event);
};

/**
 * Handles select_addon / deselect_addon event
 * @param {?Object} data - Event data
 * @param {string} addon - Add-on name
 * @param {boolean} isSelected - True if add-on is selected, False otherwise
 */
const pushSelectAddonEvent = (data, addon, isSelected) => {
    if (!(data && data.items)) {
        return;
    }

    const membership = data.items.find(item => item.item_category === 'subscription');

    if (!membership) {
        return;
    }

    const club = {
        club_name: data.club_name,
        club_id: data.club_id,
        club_services: data.club_services,
        club_founding_member: data.club_founding_member,
    };

    const eventName = isSelected ? 'select_addon' : 'deselect_addon';

    const event = new GtmParametersBuilder()
        .withEvent(eventName)
        .withParameter('membership', membership.item_name)
        .withParameter('membership_duration', membership.item_variant)
        .withParameter('payment_interval', data.payment_interval)
        .withParameter('addon', addon)
        .withParameter('club', club)
        .build();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
};

export {
    pushPersonalInformationEvent,
    pushPaymentMethodLoaded,
    pushOriginalLocation,
    pushPageView,
    pushAddToCartEvent,
    pushRemoveFromCartEvent,
    pushPaymentMethodEvent,
    pushConfirmationPageLoaded,
    pushPurchaseEvent,
    pushErrorEvent,
    pushMembershipStepCTAEvent,
    pushSelectFilterEvent,
    pushViewTypeSelectEvent,
    pushMembershipViewDetailsEvent,
    pushMembershipHideDetailsEvent,
    pushPaymentMethodSelectedEvent,
    pushSelectMembershipEvent,
    pushSelectClubEvent,
    pushSelectPaymentIntervalEvent,
    pushPaymentInfoEvent,
    pushSelectAddonEvent,
};
